import React, { FC } from 'react';
import { Seo } from '../components';
import { PageProps } from 'gatsby';
import { PageLayout } from '../components';
import config from '../../app-config.json';
import * as s from '../style.pages/index.style';
import { Typography } from '@material-ui/core';
import { siteMetadata } from '../../gatsby-config';


export const PAGE_TITLE = config.pages.ABOUT_US.title;

//////////////////////////////////  component ////////////////////////////////
const AboutUs: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} />
            <s.BodyWrap >
                <br />
                <Typography variant='h2' >{PAGE_TITLE}</Typography>
                <hr /><br /> <br />
                <Typography variant='h4' ><b>Introduction</b></Typography><br />
                <Typography align='justify' gutterBottom >On the auspicious occasion of the Shahadat of Amir-Al-Mumineen Moulana Ali Ibn Abi Talib in the year 1427H corresponding to the 11th day of October 2006, Syedna Mohammed Burhanuddin Saheb (TUS) established a Qardan Hasana Scheme by the name of <strong>“Burhani Qardan Hasana Scheme”</strong>.</Typography>
                <br />
                <Typography align='justify' gutterBottom >Qardan Hasana are given to Jama'at bodies and Individuals of the progressive repayment schemes. The principle objective of the Qardan Hasana Scheme is to provide interest-free loans for business, industry, housing, marriage, Haj and Ziyarat. The Qardan Hasana scheme also assists in education, medical, poverty relief and for public benefit.</Typography>
                <br /><br />

                <Typography gutterBottom variant='h4' ><b>Our Main Principle – “Say no to Interest”</b></Typography>
                <Typography align='justify' gutterBottom >Syedna Mohammed Burhanuddin RA the 52nd incumbent in the august office of al-Dai al-Mutlaq guided Mumineen to practice their faith in accordance with the principles of Islam and the tenets of Dawat-e-Hadiyah, for 50 years. Emphasising adherence to Islamic business ethics that include the prohibition of riba (interest) he encouraged his followers to promote the Quranic teaching of Qardan Hasana.</Typography>
                <br />
                <Typography align='justify' gutterBottom >Today, Syedna Aali Qadr Mufaddal Saifuddin (TUS) the 53rd al-Dai al-Mutlaq, son and successor to his illustrious father Syedna Mohammed Burhanuddin RA, is leading Mumineen into an era of dynamic vibrancy and renewed zeal. By devoting his efforts to the preservation of Fatemi heritage in a number of ways he is ushering in a spiritual reawakening his followers to adhere closely to the Quranic injunctions in their everyday lives.</Typography>
                <br />
                {/* <Typography gutterBottom variant='h4' ><b>Prohibition of Interest</b></Typography> */}
                <Typography align='justify' gutterBottom >The Quran forbids Muslims to give, take or use interest. This injunction of the Quran reminds people that interest does not increase wealth, on the contrary, decreases it.</Typography>
                <br />
                <Typography className='__ayat-' gutterBottom align='center'>(۳۹) وَمَا آتَيْتُم مِّن رِّبًا لِّيَرْبُوَ فِي أَمْوَالِ النَّاسِ فَلَا يَرْبُو عِندَ اللَّهِ وَمَا آتَيْتُم مِّن زَكَاةٍ تُرِيدُونَ وَجْهَ اللَّهِ فَأُوْلَئِكَ هُمُ الْمُضْعِفُونَ</Typography>
                <s.Highlight>
                    <Typography gutterBottom align='center' ><strong>“That which you give in interest, in order that it may increase other peoples wealth, increases not with Allah; but that which you give as Zakaat, seeking Allah’s pleasure, increases manifold.(30-39)”.</strong></Typography>
                </s.Highlight>
                <br /><Typography>The Quran further says:</Typography>
                <Typography align='center' gutterBottom className='__ayat-' >وَاَحَلَّ اللّٰهُ الۡبَيۡعَ وَحَرَّمَ الرِّبٰوا​</Typography>
                <s.Highlight>
                    <Typography gutterBottom align='center' ><strong>“Allah has permitted trade and forbidden interest. (2.275)”.</strong></Typography>
                </s.Highlight>
                <br /><br />

                <Typography align='justify' gutterBottom >The Quran has clearly distinguished between trade and interest but some people intentionally mix trade with interest, saying that “trade is just like interest”. However, the economic principles of Islam strictly prohibit lending or saving on interest. We are in fact encouraged to spend more and more for the common good of society with the assurance that sharing our wealth in the name of Allah Ta´ala will increase our worth in the eyes of Allah Ta´ala.</Typography>
                <br />
                <Typography align='center' gutterBottom ><b>“If you lend unto Allah Qardan Hasana, He will multiply it for you and He will forgive you, for Allah is most appreciative, most forbearing. (64.17)”.</b></Typography>
                <br /><br />

                <s.NoToInterestIllustration />


                <br /><br /><br /><br /><br /><br />
            </s.BodyWrap>
        </PageLayout>
    )
}

export default AboutUs;